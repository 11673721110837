"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.briefMemberEnvironmentSchema = exports.briefMemberSchema = exports.memberEnvironmentSchema = exports.briefMembershipSchema = exports.EMPTY_MEMBER = exports.memberSchema = exports.membershipSchema = exports.MEMBERSHIP_TYPE_PERMISSION_VALUE = exports.membershipQueryTypeSchema = exports.MembershipQueryTypeWithoutBoard = exports.MembershipQueryType = exports.membershipTypeSchema = exports.MembershipType = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
const user_1 = require("../user");
const club_1 = require("./club");
var MembershipType;
(function (MembershipType) {
    MembershipType["NO"] = "NO";
    MembershipType["FOLLOWER"] = "FOLLOWER";
    MembershipType["MEMBER"] = "MEMBER";
    MembershipType["BOARD"] = "BOARD";
    MembershipType["OWNER"] = "OWNER";
})(MembershipType = exports.MembershipType || (exports.MembershipType = {}));
exports.membershipTypeSchema = zod_1.z.nativeEnum(MembershipType);
var MembershipQueryType;
(function (MembershipQueryType) {
    MembershipQueryType["FOLLOWERS"] = "FOLLOWERS";
    MembershipQueryType["MEMBERS_CONFIRMED"] = "MEMBERS_CONFIRMED";
    MembershipQueryType["MEMBERS_UNCONFIRMED"] = "MEMBERS_UNCONFIRMED";
    MembershipQueryType["MEMBERS_ALL"] = "MEMBERS_ALL";
    MembershipQueryType["MEMBERS_OVERDUE"] = "MEMBERS_OVERDUE";
    MembershipQueryType["BOARD_MEMBERS"] = "BOARD_MEMBERS";
})(MembershipQueryType = exports.MembershipQueryType || (exports.MembershipQueryType = {}));
var MembershipQueryTypeWithoutBoard;
(function (MembershipQueryTypeWithoutBoard) {
    MembershipQueryTypeWithoutBoard["FOLLOWERS"] = "FOLLOWERS";
    MembershipQueryTypeWithoutBoard["MEMBERS_CONFIRMED"] = "MEMBERS_CONFIRMED";
    MembershipQueryTypeWithoutBoard["MEMBERS_UNCONFIRMED"] = "MEMBERS_UNCONFIRMED";
    MembershipQueryTypeWithoutBoard["MEMBERS_ALL"] = "MEMBERS_ALL";
    MembershipQueryTypeWithoutBoard["MEMBERS_OVERDUE"] = "MEMBERS_OVERDUE";
})(MembershipQueryTypeWithoutBoard = exports.MembershipQueryTypeWithoutBoard || (exports.MembershipQueryTypeWithoutBoard = {}));
exports.membershipQueryTypeSchema = zod_1.z.nativeEnum(MembershipQueryType);
exports.MEMBERSHIP_TYPE_PERMISSION_VALUE = {
    [MembershipType.NO]: 0,
    [MembershipType.FOLLOWER]: 1,
    [MembershipType.MEMBER]: 2,
    [MembershipType.BOARD]: 3,
    [MembershipType.OWNER]: 4,
};
exports.membershipSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    clubId: zod_1.z.string().uuid(),
    userId: zod_1.z.string().uuid(),
    tierId: zod_1.z.string().uuid().nullable(),
    confirmed: zod_1.z.boolean(),
    permissionType: exports.membershipTypeSchema,
    visibleType: exports.membershipTypeSchema,
    actualTill: zod_1.z.date().nullable(),
});
exports.memberSchema = user_1.userSchema.omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
    isAdmin: true,
    iban: true,
});
exports.EMPTY_MEMBER = {
    id: "",
    nameFirst: "",
    nameLast: "",
    email: null,
    address: null,
    birthdate: null,
    imageId: null,
    phone: null,
    mobile: null,
    twitter: null,
    xing: null,
    linkedin: null,
    facebook: null,
    instagram: null,
    website: null,
};
const idRe = "[\\da-f]{8}-([\\da-f]{4}-){3}[\\da-f]{12}";
const membershipIdRegularExpression = new RegExp(`^${idRe}\/${idRe}$`);
exports.briefMembershipSchema = zod_1.z.object({
    clubId: zod_1.z.string().uuid(),
    imageId: zod_1.z.string().uuid().nullable(),
    clubName: zod_1.z.string(),
    membershipType: exports.membershipTypeSchema,
});
exports.memberEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().regex(membershipIdRegularExpression),
    member: exports.memberSchema,
    club: club_1.clubSchema,
    membership: exports.membershipSchema,
    clubs: zod_1.z.array(exports.briefMembershipSchema),
});
exports.briefMemberSchema = exports.memberSchema.pick({
    id: true,
    nameFirst: true,
    nameLast: true,
    imageId: true,
});
exports.briefMemberEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().regex(membershipIdRegularExpression),
    briefMember: exports.briefMemberSchema,
    membership: exports.membershipSchema,
    isInvisible: zod_1.z.boolean(),
});
