import React, { useCallback, useMemo } from "react";
import { omit } from "lodash";
import { useMutation } from "@apollo/client";
import { Club, ClubPatch } from "shared";
import { ParseError } from "src/lib";
import { Modal } from "client-lib";
import { UpdateClubAdmin, UpdateClubVars, UpdateClubData } from "../queries";
import { ClubForm } from "./club-form";

interface Props {
  club: Club;
  onCloseRequest: () => void;
  onUpdated?: () => void;
}

export function UpdateClubModal({ club, onCloseRequest, onUpdated }: Props) {
  const [update, { loading, error }] = useMutation<
    UpdateClubData,
    UpdateClubVars
  >(UpdateClubAdmin);

  const initialValues = useMemo<ClubPatch>(
    () =>
      omit(club, [
        "id",
        "name",
        "categoryIds",
        "reachLocationIds",
        "imageId",
      ]) as ClubPatch,
    [club]
  );

  const handleSubmit = useCallback(
    async (patch: ClubPatch) => {
      await update({
        variables: {
          id: club.id,
          patch,
        },
      });
      onUpdated?.();
      onCloseRequest();
    },
    [update, onUpdated, onCloseRequest, club]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title="Edit club" width={800}>
      {error ? <ParseError error={error} /> : null}
      <ClubForm
        type="edit"
        initialValues={initialValues}
        disabled={loading}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}
