import React, { ReactNode, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  ColorItem,
  Button,
  useBoolean,
  gutters,
} from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import { Club, File, imgixURL } from "shared";
import {
  ClubEnvironmentQuery,
  ClubEnvironmentData,
  ClubEnvironmentVars,
  UpdateClubImage,
  UpdateClubImageData,
  UpdateClubImageVars,
} from "./queries";
import { CategoryList } from "../category/category-list";
import { UpdateClubModal } from "./club-form/update-club-modal";
import { FileUploader } from "../file/file-uploader";

const Controls = styled.div`
  margin-bottom: ${gutters.xl}px;
  padding-top: ${gutters.md}px;
`;

function Wrapper({
  children,
  club,
  clubId,
}: {
  clubId: string;
  children?: ReactNode;
  club?: Club;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Clubs", url: "/clubs" },
          { title: club ? club.name : `${clubId}` },
        ]}
      />
      <h1>Club {club ? `\u00ab${club.name}\u00bb` : `${clubId}`}</h1>
      {children}
    </Layout>
  );
}

export function ClubPage() {
  const { params } = useRouteMatch<{ clubId: string }>();

  const [isUpdateClubOpen, { set: openUpdateClub, reset: closeUpdateClub }] =
    useBoolean(false);

  const { data, loading, error, refetch } = useQuery<
    ClubEnvironmentData,
    ClubEnvironmentVars
  >(ClubEnvironmentQuery, {
    variables: {
      clubId: params.clubId,
    },
  });

  const [updateClubImage] = useMutation<
    UpdateClubImageData,
    UpdateClubImageVars
  >(UpdateClubImage);

  const handleNewImage = useCallback(
    async (image: File | null) => {
      await updateClubImage({
        variables: {
          id: params.clubId,
          imageId: image ? image.id : null,
        },
      });
      refetch();
    },
    [params.clubId, refetch, updateClubImage]
  );

  if (loading) {
    return (
      <Wrapper clubId={params.clubId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper clubId={params.clubId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper clubId={params.clubId} />;
  }
  const {
    club,
    club: {
      id,
      name,
      abbreviation,
      color,
      description,
      locationId,
      isActive,
      isPublic,
    },
    categories,
  } = data.clubEnvironment;

  return (
    <Wrapper clubId={params.clubId} club={club}>
      <Controls>
        <Button color="secondary" onClick={openUpdateClub}>
          Edit club
        </Button>
      </Controls>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>ID</TH>
            <TD>{id}</TD>
          </TR>
          <TR>
            <TH>Name</TH>
            <TD>{name}</TD>
          </TR>
          <TR>
            <TH>Abbreviation</TH>
            <TD>{abbreviation}</TD>
          </TR>
          <TR>
            <TH>Color</TH>
            <TD>
              <ColorItem color={color || "#aaaaaa"} size={15} />
            </TD>
          </TR>
          <TR>
            <TH>Short Description</TH>
            <TD>{description}</TD>
          </TR>
          <TR>
            <TH>Location</TH>
            <TD>{locationId}</TD>
          </TR>
          <TR>
            <TH>Active</TH>
            <TD>{isActive ? "Yes" : "No"}</TD>
          </TR>
          <TR>
            <TH>Public</TH>
            <TD>{isPublic ? "Yes" : "No"}</TD>
          </TR>
        </TBody>
      </Table>

      <h1>Image</h1>
      {club.imageId ? (
        <img src={imgixURL(club.imageId, "?w=400")} />
      ) : (
        "No image"
      )}
      <FileUploader onCompleted={handleNewImage} />
      <h1>Categories</h1>
      <CategoryList categories={categories} />
      {isUpdateClubOpen && (
        <UpdateClubModal
          club={club}
          onCloseRequest={closeUpdateClub}
          onUpdated={refetch}
        />
      )}
    </Wrapper>
  );
}
