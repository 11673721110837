import gql from "graphql-tag";
import {
  clubSchema,
  ClubEnvironment,
  clubEnvironmentSchema,
  ClubInput,
  Club,
  ClubPatch,
} from "shared";
import { getFieldsFromSchema } from "client-lib";

export const CLUB_FULL_FRAGMENT = gql`
  fragment ClubFullFragment on Club {
    ${getFieldsFromSchema(clubSchema)}
  }
`;

export const CLUB_ENVIRONMENT_FULL_FRAGMENT = gql`
  fragment ClubEnvironmentFullFragment on ClubEnvironment {
    ${getFieldsFromSchema(clubEnvironmentSchema)}
  }
`;

export const Clubs = gql`
  query Clubs($categoryIds: [String]) {
    Clubs(categoryIds: $categoryIds) {
      club {
        ...ClubFullFragment
      }
    }
  }
  ${CLUB_FULL_FRAGMENT}
`;

export interface ClubsVars {
  categoryIds?: string[] | null;
}

export type ClubsData = {
  Clubs: ClubEnvironment[];
};

export const ClubEnvironmentQuery = gql`
  query ClubEnvironment($clubId: String!) {
    clubEnvironment(clubId: $clubId) {
      ...ClubEnvironmentFullFragment
    }
  }
  ${CLUB_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface ClubEnvironmentVars {
  clubId: string;
}

export type ClubEnvironmentData = {
  clubEnvironment: ClubEnvironment;
};

export const UpdateClubImage = gql`
  mutation UpdateClubImage($id: String!, $imageId: String) {
    UpdateClubImage(id: $id, imageId: $imageId) {
      ...ClubFullFragment
    }
  }
  ${CLUB_FULL_FRAGMENT}
`;

export interface UpdateClubImageVars {
  id: string;
  imageId: string | null;
}

export type UpdateClubImageData = {
  UpdateClubImage: Club;
};

export const CreateClub = gql`
  mutation CreateClub($input: ClubInput!) {
    CreateClub(input: $input) {
      ...ClubFullFragment
    }
  }
  ${CLUB_FULL_FRAGMENT}
`;

export interface CreateClubVars {
  input: ClubInput;
}

export type CreateClubData = {
  Club: Club;
};

export const UpdateClubAdmin = gql`
  mutation UpdateClubAdmin($patch: ClubPatch!, $id: String!) {
    UpdateClubAdmin(patch: $patch, id: $id) {
      ...ClubFullFragment
    }
  }
  ${CLUB_FULL_FRAGMENT}
`;

export interface UpdateClubVars {
  id: string;
  patch: ClubPatch;
}

export type UpdateClubData = {
  UpdateClubAdmin: Club;
};
