"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clubEventInputSchema = exports.clubEventSchema = exports.reminderTypeSchema = exports.ReminderType = exports.clubEventGeoLocationTypeSchema = exports.ClubEventGeoLocationType = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
const geo_location_1 = require("../geo-location");
var ClubEventGeoLocationType;
(function (ClubEventGeoLocationType) {
    ClubEventGeoLocationType["MEETING_POINT"] = "MEETING_POINT";
    ClubEventGeoLocationType["VENUE"] = "VENUE";
    ClubEventGeoLocationType["REGION"] = "REGION";
})(ClubEventGeoLocationType = exports.ClubEventGeoLocationType || (exports.ClubEventGeoLocationType = {}));
exports.clubEventGeoLocationTypeSchema = zod_1.z.nativeEnum(ClubEventGeoLocationType);
var ReminderType;
(function (ReminderType) {
    ReminderType["FEED"] = "FEED";
    ReminderType["NOTIFICATIONS"] = "NOTIFICATIONS";
    ReminderType["EMAIL"] = "EMAIL";
})(ReminderType = exports.ReminderType || (exports.ReminderType = {}));
exports.reminderTypeSchema = zod_1.z.nativeEnum(ReminderType);
exports.clubEventSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    clubPostId: zod_1.z.string().uuid(),
    program: zod_1.z.string().nullable(),
    geoLocationType: exports.clubEventGeoLocationTypeSchema,
    geoLocation: geo_location_1.geoLocationSchema,
    startAt: zod_1.z.date(),
    finishAt: zod_1.z.date(),
    isAllDay: zod_1.z.boolean(),
    registrationDeadlineAt: zod_1.z.date().nullable(),
    priceRp: zod_1.z.number().int().positive().nullable(),
    deadlineReminderBefore: zod_1.z.number().int().nullable(),
    deadlineReminderType: exports.reminderTypeSchema.nullable(),
    eventReminderBefore: zod_1.z.number().int().nullable(),
    eventReminderType: exports.reminderTypeSchema.nullable(),
});
(0, services_1.checkSchema)(exports.clubEventSchema);
const clubEventInputSchemaRaw = exports.clubEventSchema
    .pick({
    id: true,
    program: true,
    geoLocationType: true,
    geoLocation: true,
    startAt: true,
    finishAt: true,
    isAllDay: true,
    registrationDeadlineAt: true,
    priceRp: true,
    deadlineReminderBefore: true,
    deadlineReminderType: true,
    eventReminderBefore: true,
    eventReminderType: true,
})
    .extend({
    deadlineReminder: zod_1.z.boolean(),
    eventReminder: zod_1.z.boolean(),
});
exports.clubEventInputSchema = clubEventInputSchemaRaw.superRefine((val, ctx) => {
    if (val.deadlineReminder) {
        if (!val.deadlineReminderBefore) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                message: "Required",
                path: ["deadlineReminderBefore"]
            });
        }
        if (!val.deadlineReminderType) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                message: "Required",
                path: ["deadlineReminderType"]
            });
        }
    }
    if (val.eventReminder) {
        if (!val.eventReminderBefore) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                message: "Required",
                path: ["eventReminderBefore"]
            });
        }
        if (!val.eventReminderType) {
            ctx.addIssue({
                code: zod_1.z.ZodIssueCode.custom,
                message: "Required",
                path: ["eventReminderType"]
            });
        }
    }
});
