"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.orderSchema = exports.orderStatusSchema = exports.OrderStatus = exports.orderFinancialStatusSchema = exports.OrderFinancialStatus = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
var OrderFinancialStatus;
(function (OrderFinancialStatus) {
    OrderFinancialStatus["AWAITING"] = "AWAITING";
    OrderFinancialStatus["PAID"] = "PAID";
})(OrderFinancialStatus = exports.OrderFinancialStatus || (exports.OrderFinancialStatus = {}));
exports.orderFinancialStatusSchema = zod_1.z.nativeEnum(OrderFinancialStatus);
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["CREATED"] = "CREATED";
    OrderStatus["ACTUAL"] = "ACTUAL";
    OrderStatus["CANCELED"] = "CANCELED";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
exports.orderStatusSchema = zod_1.z.nativeEnum(OrderStatus);
exports.orderSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    status: exports.orderStatusSchema,
    financialStatus: exports.orderFinancialStatusSchema,
    userId: zod_1.z.string().uuid(),
});
(0, services_1.checkSchema)(exports.orderSchema);
