"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environmentSchema = exports.myEnvironmentSchema = void 0;
const zod_1 = require("zod");
const user_1 = require("./user");
exports.myEnvironmentSchema = zod_1.z.object({
    user: user_1.userSchema.nullable(),
});
exports.environmentSchema = zod_1.z.object({
    myEnvironment: exports.myEnvironmentSchema.nullable(),
});
