"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authChannelSchema = exports.tokenAuthChannelSchema = exports.authChannelTypeSchema = exports.AuthChannelType = void 0;
const zod_1 = require("zod");
const services_1 = require("../services");
var AuthChannelType;
(function (AuthChannelType) {
    AuthChannelType["EMAIL"] = "EMAIL";
    AuthChannelType["ADMIN_ISSUED"] = "ADMIN_ISSUED";
})(AuthChannelType = exports.AuthChannelType || (exports.AuthChannelType = {}));
exports.authChannelTypeSchema = zod_1.z.nativeEnum(AuthChannelType);
// the schema of user being stored in auth token
// should not contain any refinements!
exports.tokenAuthChannelSchema = zod_1.z.object({
    type: exports.authChannelTypeSchema,
    channelKey: zod_1.z.string(),
});
const uncheckedAuthChannelSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    userId: zod_1.z.string().uuid().nullable(),
    type: exports.authChannelTypeSchema,
    channelKey: zod_1.z.string(),
    passwordHash: zod_1.z.string().nullable(),
    verifiedAt: zod_1.z.date().nullable(),
    isSuspended: zod_1.z.boolean(),
});
exports.authChannelSchema = uncheckedAuthChannelSchema.refine((authChannel) => {
    switch (authChannel.type) {
        case "EMAIL":
            return (services_1.EMAIL_REGEXP.test(authChannel.channelKey) &&
                authChannel.passwordHash !== null);
        default:
            return true;
    }
}, {
    message: `channelKey format is invalid`,
});
(0, services_1.checkSchema)(exports.authChannelSchema);
