import gql from "graphql-tag";
import { User } from "shared";
import { USER_FULL_FRAGMENT } from "client-lib";

export const Users = gql`
  query Users {
    Users {
      ...UserFullFragment
    }
  }
  ${USER_FULL_FRAGMENT}
`;

export interface UsersVars {}

export type UsersData = {
  Users: User[];
};

export const UserEnvironmentQuery = gql`
  query UserEnvironment($userId: String!) {
    UserEnvironment(userId: $userId) {
      user {
        ...UserFullFragment
      }
    }
  }
  ${USER_FULL_FRAGMENT}
`;

export interface UserEnvironmentVars {
  userId: string;
}

export type UserEnvironmentData = {
  UserEnvironment: {
    user: User;
  };
};

export const UserToken = gql`
  query UserToken($userId: String!) {
    UserToken(userId: $userId)
  }
`;

export interface UserTokenVars {
  userId: string;
}

export type UserTokenData = {
  UserToken: string;
};
