"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.invisibilitySchema = void 0;
const zod_1 = require("zod");
exports.invisibilitySchema = zod_1.z.object({
    clubId: zod_1.z.string().uuid(),
    userId: zod_1.z.string().uuid(),
    actualTill: zod_1.z.date(),
    leaveClubAfterwards: zod_1.z.boolean(),
});
